import React from 'react';
import { navigate } from 'gatsby';
import Layout from '@layouts/default';
import SEO from '@layouts/seo';
import { Button, InfoBox } from '@components/styledComponents/index';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <InfoBox>
      <h1>404: صفحة غير موجودة</h1>
      <p>المعذرة هذه الصفحة غير موجودة!</p>
      <Button onClick={() => navigate('/')}>عودة للصفحة الرئيسية</Button>
    </InfoBox>
  </Layout>
);

export default NotFoundPage;
